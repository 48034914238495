import { googleLogout } from "@react-oauth/google";
import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./components/Login.js";
import Logout from "./components/Logout.js";
import NonContract from "./components/non-contract.js";
import Payment from "./components/payment.js";
import Trips from "./components/Trips.js";
import { getCookie } from "./utils/getCookie.js";
import { extractUrlData } from "./utils/getUrlData.js";

const App = () => {
  const [oAuthCode, setOAuthCode] = useState(getCookie("oAuthCode"));
  const [authorization, setAuthorization] = useState();
  const [trips, setTrips] = useState([]);
  const { route, tripId, token } = extractUrlData();
  const isIdAndTokenValid = tripId && token;
  const isInPaymentRoute = route.includes("non-contract/payment");
  const isNonContractRoute = route === "/non-contract";
  const loggedIn = authorization !== undefined;

  const renderRoute = () => {
    return loggedIn ? (
      <Trips trips={trips} setTrips={setTrips} authorization={authorization} />
    ) : (
      <Login
        authorization={authorization}
        setAuthorization={setAuthorization}
        oAuthCode={oAuthCode}
        setOAuthCode={setOAuthCode}
      />
    );
  };

  const NonContractRoutes = () => {
    return (
      <Routes>
        <Route index element={<NonContract />} />
        <Route path="payment" element={<Payment />} />
      </Routes>
    );
  };

  return isIdAndTokenValid && isInPaymentRoute ? (
    <Payment />
  ) :  isIdAndTokenValid && isNonContractRoute ? (
    <NonContract />
  ) : (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={renderRoute()} />
        <Route path="/trips" element={renderRoute()} />
        <Route path="/non-contract/*" element={<NonContractRoutes />} />
        <Route
          path="/logout"
          element={
            <Logout
              authorization={authorization}
              setAuthorization={setAuthorization}
              googleLogout={googleLogout}
              setOAuthCode={setOAuthCode}
              oAuthCode={oAuthCode}
            />
          }
        />
        <Route
          path="/login"
          element={
            <Login
              authorization={authorization}
              setAuthorization={setAuthorization}
              oAuthCode={oAuthCode}
              setOAuthCode={setOAuthCode}
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
