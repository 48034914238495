import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { customFetch } from "../utils/customFetch.js";
import { getApiRoot } from "../utils/getApiRoot.js";
import { GoogleLogin } from "@react-oauth/google";
import { getCookie } from "../utils/getCookie.js";
import logo from "./images/wholeLogo.webp";
import classes from "./login.module.css";
import styles from "../app.module.css"
import Trips from "./Trips.js";

function App({ authorization, setAuthorization, oAuthCode, setOAuthCode }) {
  const [googleInitialized, setGoogleInitialized] = useState(false);

  // Callback function to handle the response from Google One Tap
  const handleCredentialResponse = async (credentialResponse) => {
    const fetchAuthorization = async () => {
      const apiRoot = getApiRoot();
      let options = { credentials: "include" }; //this passes the googleIdToken to the fetch call
      const authorizationResponse = await customFetch(`${apiRoot}/api/token`, options);
      setAuthorization(await authorizationResponse.json());
    };

    const { credential } = credentialResponse;
    // Extract the JWT credential from the response
    const jwtToken = credential;
    console.log("Received JWT:", jwtToken);
    document.cookie = "oAuthCode=" + jwtToken; //this will authenticate all requests to the backend
    if (jwtToken) {
      fetchAuthorization();
    }
    setOAuthCode(jwtToken);
  };

  const handleManualLoginSuccess = (credentialResponse) => {
    handleCredentialResponse(credentialResponse);
  };

  useEffect(() => {
    const cookie = getCookie("oAuthCode");
    const auth = async () => {
      if (!cookie) {
        try {
          // Initialize Google One Tap if the cookie is not present
          await window.google.accounts.id.initialize({
            client_id: "257921541428-mj6719g80bei7sqe6dshso09v3nn99m3.apps.googleusercontent.com",
            callback: handleCredentialResponse,
            // use_fedcm_for_prompt: true
          });

          // Render the Google One Tap prompt
          await window.google.accounts.id.prompt();
          setGoogleInitialized(true);
        } catch (error) {
          console.error("Error using Google One Tap Login: ", error);
        }
        return;
      }

      if (!authorization) {
        const apiRoot = getApiRoot();
        let options = { credentials: "include" }; //this passes the googleIdToken to the fetch call
        const authorizationResponse = await customFetch(`${apiRoot}/api/token`, options);
        setAuthorization(await authorizationResponse.json());
      }
    };
    auth();
  }, [authorization, setAuthorization, setOAuthCode]);

  if (!oAuthCode) {
    return (
      <div className={classes.login}>
        <div className={classes.loginImage}>
          <img src={logo} alt="Mustang Transportation logo" className={classes.loginLogo} />
        </div>
        <div className={classes.loginFlex}>
          <div className={classes.loginFlexSection}>
            <h2 className={classes.loginH2}>WELCOME</h2>
            <p className={classes.loginBodyText}>Login with your Mustang account</p>
            <p className={classes.loginBodyText}>
              Payment service is provided by Intuit Payments Inc.
            </p>
          </div>
          <div className={classes.loginFlexSection}>
          <div className={classes.loginGoogle_button}>
              {/* Login */}

              <GoogleLogin
                id={styles.googleLogin}
                onSuccess={handleManualLoginSuccess}
                onError={(error) => console.error("Login Failed:", error)}
                className={classes.button}                 
              />

            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <Navigate to="/trips" element={<Trips authorization={authorization} />} />;
  }
}

export default App;
