export const extractUrlData = () => {
  const route = window.location.pathname;
  const hash = window.location.hash;
  const hashParts = hash.split("#");
  const [tripId, rest] = hashParts[1]?.split("?") || [];
  const token = rest?.split("=")[1];

  return { route, tripId, token };
};

export const extractNonContractLinkFromURL = (url) => {
  const formattedHash = window.location.hash.substring(1).replace(/\$/g, "&");
  const params = new URLSearchParams(formattedHash);
  const data = {};

  // Identify and store the button type and its value

  const selectedButton = params.get("selectedButton");

  // Based on the button type, extract the relevant links
  if (selectedButton === "partNowPartLaterBtn") {
    data.link1 = params.get("link1");
    data.link2 = params.get("link2");
  } else {
    data.link = params.get("link");
  }

  // Extract other parameters
  data.clientName = params.get("clientName");
  data.subTotal = params.get("subTotal");
  data.tax = params.get("tax");
  data.total = params.get("total");
  data.selectedButton = selectedButton;

  return data;
};
