import React from "react";
import { convertNumberToCurrency } from "../utils/convertNumberToCurrency";
import { extractNonContractLinkFromURL } from "../utils/getUrlData";
import CircleCheckIcon from "./circle-check-icon";
import fullLogo from "./images/fullLogo.png";
import "./non-contract.css";
import PaymentStyles from "./payment.module.css";
export default function Payment() {
  const urlData = extractNonContractLinkFromURL(window.location.href);
  const { clientName, link, link1, link2, subTotal, tax, total, selectedButton } = urlData;
  console.log(urlData);

  return (
    <>
      <div className="titleOrLogo">
        <img src={fullLogo} alt="Mustang Transportation logo" />
      </div>
      <div className={PaymentStyles.paymentBody}>
        <div className="LeftSide">
          <div className="Instructions">
            REVIEW {">"} SIGN {">"} PAY
          </div>

          <div className={PaymentStyles.card}>
            <div className={PaymentStyles.content}>
              <CircleCheckIcon className={PaymentStyles.icon} />
              <div className={PaymentStyles.textCenter}>
                <h2 className={PaymentStyles.heading}>Complete your Order</h2>
                <p className={PaymentStyles.subtext}>Thank you for your purchase.</p>
                <p className={PaymentStyles.subtext}>Proceed to the payment!</p>
              </div>
              <div className={PaymentStyles.divider}>
                <div className={PaymentStyles.flexBetween}>
                  <p className={PaymentStyles.total}>Client</p>
                  <p className={PaymentStyles.total}>{`${clientName}`}</p>
                </div>

                <div className="flex flex-col gap-2">
                  <div className={PaymentStyles.flexBetween}>
                    <span>Subtotal</span>
                    <span>{convertNumberToCurrency(subTotal)}</span>
                  </div>
                  <div className={PaymentStyles.flexBetween}>
                    <span>Taxes</span>
                    <span>{convertNumberToCurrency(tax)}</span>
                  </div>
                </div>
                <div className={PaymentStyles.flexBetween}>
                  <p className={PaymentStyles.total}>Total</p>
                  <p className={PaymentStyles.total}>{convertNumberToCurrency(total)}</p>
                </div>
              </div>
              {selectedButton === "partNowPartLaterBtn" ? (
                <div className={PaymentStyles.linkGroup}>
                  <a
                    href={link1}
                    className={PaymentStyles.linkPrimary}
                    target="_blank"
                    rel="noopener noreferrer">
                    First Payment
                  </a>
                  <a
                    href={link2}
                    className={PaymentStyles.linkSecondary}
                    target="_blank"
                    rel="noopener noreferrer">
                    Second payment
                  </a>
                </div>
              ) : (
                <div className={PaymentStyles.linkGroup}>
                  <a
                    href={link}
                    className={PaymentStyles.linkPrimary}
                    target="_blank"
                    rel="noopener noreferrer">
                    Pay in Full
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
