import PropTypes from "prop-types";
import React, { useState } from "react";
import classes from "./Trips.module.css";
import Filter from "./filter";
import Header from "./header";
import Sidebar from "./sidebar";
import Table from "./table";

const Trips = ({ trips, setTrips, authorization, setLoggedIn }) => {
  const defaultTripModalData = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    companyName: "",
    tripStatus: "Pending",
    paymentStatus: "Unpaid",
    disableCompanyName: false,
    contract: false,
    quoteNumber: "",
    quoteDate: "",
    groupName: "",
    groupPhone: "",
    groupLeader: "",
    numOfPassengers: 0,
    otherInfo: "",
    pickupLocation: "",
    destination: "",
    departureDate: "",
    returnDate: "",
    estimatedMileage: "",
    notes: "",
    taxRate: 0,
    invoiceItems: []
  };
  const [showTripModal, setShowTripModal] = useState(false);
  const [tripModalData, setTripModalData] = useState(defaultTripModalData);
  const [currentPage, setCurrentPage] = useState(1);

  const resetTripModal = () => {
    setTripModalData(defaultTripModalData);
  };

  console.log(trips.length);

  return (
    <div className={classes.Trips}>
      <Sidebar authorization={authorization} id="sidebar" className={classes.TripsSidebar} />
      <div className={classes.TripsSection}>
        <Header 
          resetTripModal={resetTripModal}
          trips={trips}
          setTrips={setTrips}
          tripModalData={tripModalData}
          setTripModalData={setTripModalData}
          id="header"
          showTripModal={showTripModal}
          setShowTripModal={setShowTripModal}
          className={classes.TripsHeader}
        />

        <div className={classes.TripsBody}>
          <Filter 
            id="filter" 
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}             
            setTrips={setTrips} 
            className={classes.TripsFilter} 
          />
          <Table
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}          
            id="table"
            setTripModalData={setTripModalData}
            setShowTripModal={setShowTripModal}
            trips={trips}
            className={classes.TripsTable}
          />
        </div>
      </div>
    </div>
  );
};

export default Trips;

// This code is to check the prop types
// Makes the code more readable and easier to debug
Trips.propTypes = {
  trips: PropTypes.array,
  setTrips: PropTypes.func,
  authorization: PropTypes.object
};
